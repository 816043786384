/*
|-------------------------------------------------------------------------------
| App Setup
|-------------------------------------------------------------------------------
|
| Connects the DOM to our App. Exposes core App data at DEV time.
|
| It also should be the only file that is importing the store - all other usages
| of store are through react connectors or middleware.
|
*/

import 'core-js';
import 'utils/globals';

import React, { useEffect } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { StylesProvider } from '@rexlabs/styling';
import { TextProvider } from '@rexlabs/text';
import { PortalProvider } from '@rexlabs/portal';
import store from 'src/store';
import { api } from 'shared/utils/api-client';
import { initTheme, TEXTS } from 'src/theme';
import AuthApp from 'view/app';
import config from 'shared/utils/config';
import { initSegment } from 'shared/utils/analytics';
import analyticsMiddleware from 'shared/utils/analytics/middleware';
import Analytics from 'shared/utils/vivid-analytics';

// Note: Ensure this is imported last, as it needs to inject styles last.
import COMPONENTS from 'theme/components';
import { useAppId } from './hooks/use-app-id';

config.SEGMENT_ID &&
  initSegment(config.SEGMENT_ID, { integrations: { FullStory: false } });
Analytics.setHandlers([analyticsMiddleware]);

window.app = {
  config,
  store,
  api
};

// Setup global parts of theme
initTheme();

if (config.AUTH_APP_BUGSNAG_ID && window.bugsnag) {
  try {
    window.bugsnagClient = window.bugsnag({
      beforeSend: (report) => {
        // We want to ignore `ResizeObserver loop limit exceeded` errors, since this is
        // just a browser warning that is unfortunately handled through `window.onerror`
        // and is expected behaviour :/
        // See https://github.com/WICG/resize-observer/issues/38#issuecomment-334816361
        if (
          [
            'ResizeObserver loop limit exceeded',
            'ResizeObserver loop completed with undelivered notifications.'
          ].includes(report.errorMessage)
        ) {
          report.ignore();
        }
      },
      apiKey: config.AUTH_APP_BUGSNAG_ID,
      appVersion: config.RELEASE?.VERSION,
      releaseStage: config.RELEASE?.STAGE || 'local',
      metaData: { app: 'auth-app' } // Adding meta data here because we aren't setting user/account metaData anywhere else in auth-app
    });
  } catch (e) {
    console.error(`Bugsnag configuration variables are invalid:\n${e}`);
  }
}

function AuthAppMount() {
  useEffect(() => {
    // NOTE: this is just to imitate the typekit behaviour to set a class
    //  once app is mounted ... TODO: better solution or simply remove
    const root = document.getElementsByTagName('html')[0];
    root.classList.add('ready');
  }, []);

  const appId = useAppId();

  return (
    <StylesProvider components={COMPONENTS(appId)} debug={__DEV__}>
      <TextProvider text={TEXTS}>
        <AuthApp />
      </TextProvider>
    </StylesProvider>
  );
}

render(
  <PortalProvider>
    <Provider store={store}>
      <AuthAppMount />
    </Provider>
  </PortalProvider>,
  document.querySelector('#app')
);
